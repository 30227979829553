@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

/* Nav Logo */
.logo {
  width: 140px;
  height: inherit;
  height: -webkit-fill-available;
}

.logo a {
  display: block;
  width: 100%;
  height: 100%;
  background: url(/static/media/logo-white.8b7ba2a2.png) no-repeat left;
  background-size: contain;
}
.logo-accessibility {
  position: absolute;
  top: -20000px;
  left: -20000px;
}

/* Brand color */
.brand-color {
  background-color: #1b2e2e!important;
}
.brand-color-button:hover {
  background-color: #121f1f!important;
}
.brand-color-darken {
  background-color: #121f1f!important;
}

.navlink {
  font-size: 0.85rem;
}
.navlink a {
  font-size: 0.85rem;
}

.transparent-bg {
  background-color: transparent;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  box-shadow: none;
}
.navbar-bg {
  background-color: #1b2e2e!important;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.header {
  background-image: url(/static/media/headercover.92520007.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: -1;

}
.header::before {
  content: "";
  background: #0000006b;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 0;
}

.header-image {
  background-position: center;
  background-size: cover;
  position: relative;
  height: 30vh;
  z-index: -1;
}

.header-image::before {
  content: "";
  background-image: -webkit-gradient(linear, left bottom, left top, from(#00000079), to(#000000bb));
  background-image: -webkit-linear-gradient(bottom, #00000079, #000000bb);
  background-image: linear-gradient(to top, #00000079, #000000bb);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

/* Helpers */
.py-1 {
  padding: 10px 0 10px 0;
}
.py-2 {
  padding: 20px 0 20px 0;
}
.py-3 {
  padding: 30px 0 30px 0;
}
.py-4 {
  padding: 40px 0 40px 0;
}
.py-5 {
  padding: 50px 0 50px 0;
}
.py-10 {
  padding: 100px 0 100px 0;
}

.pt-10 {
  padding-top: 100px;
}

.px-3 {
  padding: 0 30px 0 30px;
}
.mt-2 {
  margin-top: 20px;
}
.mb-2 {
  margin-bottom: 20px;
}

.mt-0 {
  margin-top: 0;
}

/* Contact Modal */
.trigger {
  border: 2px white solid;
  border-radius: 25px;
  background-color: transparent;
  color: white;
  padding: 0.75rem;
  cursor: pointer;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  font-weight: 500
}
.trigger:hover {
  background: white;
  color: #454545
}
.trigger:focus {
  background-color: transparent;
}

.title-animation {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}
.subtitle-animation {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
}
.subtitle-animation-2 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

/* Navbar scroll effect */
.scrollEffect {
  position: fixed;
  top: 0;
  -webkit-transition: top 0.3s;
  transition: top 0.3s;
  z-index: 2;
}

.scrollEffect--hide {
  top: -80px;
}

.full-height {
  height: 100vh;
}

/* Product slider */
.slider-container {
  display: flex;
  padding: 0 55px;
}

.slider-item {
  flex: 0 0 19.7%;
  text-align: center;
  margin: 0 2px;
  -webkit-transition: -webkit-transform 300ms ease 100ms;
  transition: -webkit-transform 300ms ease 100ms;
  transition: transform 300ms ease 100ms;
  transition: transform 300ms ease 100ms, -webkit-transform 300ms ease 100ms;
  min-height: 160px;
  background-size: cover;
  position: relative;
}
.slider-item::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  background-image: -webkit-gradient(linear, left bottom, left top, from(#000000a8), to(#00000073));
  background-image: -webkit-linear-gradient(bottom, #000000a8, #00000073);
  background-image: linear-gradient(to top, #000000a8, #00000073);
  left: 0;
  z-index: 0;
}

.slider-container:hover .slider-item {
  -webkit-transform: translateX(-25%);
          transform: translateX(-25%);
}

.slider-item:hover ~ .slider-item {
  -webkit-transform: translateX(25%);
          transform: translateX(25%);
}

.slider-item:hover {
  -webkit-transform: scale(1.5) !important;
          transform: scale(1.5) !important;
}

/*Footer*/

.social-icons {
  display: flex;
  justify-content: center;
}
.social-icon {
  font-size: 2rem;
  letter-spacing: 1.25rem;
  color: white;
  height: auto;
  -webkit-transition: 0.15s ease-in-out;
  transition: 0.15s ease-in-out;
}
.social-icon:hover {
  color: #33CCFF;
}

.footer-image {
  max-width: 200px;
  width: inherit;
}
.footer-text {
  margin: 0;
}

/* Marketing */

.marketing-background {
  background-image: url(/static/media/wave-bg.3ce1766c.jpg)
}
.marketing-image {
  width: 120px;
}

.home-product-image {
  width: 300px;
}
